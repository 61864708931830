<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="10" lg="8">
          <h5 class="text-center"><i class="el-icon-loading" /></h5>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'InitPage',
  computed: {
    ...mapState([
      'capability'
    ]),
  },
  mounted() {
    if (this.capability) {
      if (localStorage.lang !== this.capability.user_profile.locale) {
        localStorage.lang = this.capability.user_profile.locale;
        window.location.reload();
        return;
      }

      let redirect = '';
      if (this.$route.query.redirect) {
        redirect = window.atob(this.$route.query.redirect);
      }

      if (redirect && !redirect.startsWith('/pages/init-page')) {
        console.log(redirect);
        this.$router.push(redirect);
      } else {
        this.$router.push('/');
      }
    }
    // this.$router.push('/pages/login');
  }
}
</script>
